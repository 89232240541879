var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "div",
              {
                staticClass:
                  "row d-flex justify-content-between align-items-center"
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("VueCtkDateTimePicker", {
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        locale: "fr",
                        range: true,
                        formatted: "ll",
                        color: "#34495e",
                        "no-label": true,
                        "custom-shortcuts": _vm.customDateRangeShortcuts,
                        "only-date": true,
                        "auto-close": true,
                        "no-header": true
                      },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-2 mb-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: { click: _vm.searchTP }
                    },
                    [_vm._v("Recherche")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-3 d-flex justify-content-end" },
                  [
                    _c("div", { staticClass: "btn-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.selectedReceive },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.receivedList()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-envelope" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge bg-danger text-white" },
                            [_vm._v(_vm._s(_vm.receives.length))]
                          ),
                          _vm._v(" Reçu ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.selectedArrival },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.arrivalsData()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-plane-arrival" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge bg-success text-white" },
                            [_vm._v(_vm._s(_vm.arrivals.length))]
                          ),
                          _vm._v(" Arrivé ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.selectedDeparture },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.departuresData()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-plane-departure" }),
                          _c(
                            "span",
                            { staticClass: "badge bg-warning text-white ml-1" },
                            [_vm._v(" " + _vm._s(_vm.departures.length))]
                          ),
                          _vm._v(" Départ ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary",
                          class: { active: _vm.selectedArchive },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.archivedList()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-archive" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "badge bg-info text-white" },
                            [_vm._v(_vm._s(_vm.archives.length))]
                          ),
                          _vm._v(" Archives ")
                        ]
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "b-card",
              { staticClass: "mb-1", attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-body",
                  { staticClass: "shadow-lg" },
                  [
                    _c("dataset", {
                      attrs: { "ds-data": _vm.data },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var ds = ref.ds
                            return [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c("div", {}, [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-hover d-md-table"
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            _vm._l(_vm.cols, function(th) {
                                              return _c(
                                                "th",
                                                {
                                                  key: th.field,
                                                  staticClass: "text-center",
                                                  style:
                                                    th.field == "objet"
                                                      ? "width: 20%;"
                                                      : ""
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(th.name) + " "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ]),
                                        _c("dataset-item", {
                                          attrs: { tag: "tbody" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  var rowIndex = ref.rowIndex
                                                  return [
                                                    _c(
                                                      "tr",
                                                      {
                                                        class: {
                                                          "bg-soft-info":
                                                            _vm.selectedIndex ==
                                                            rowIndex
                                                        },
                                                        staticStyle: {
                                                          cursor: "pointer"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectRow(
                                                              row,
                                                              rowIndex
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowIndex + 1
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.structure
                                                                  .name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.total_visitors
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            row.type_act ==
                                                            "arrival"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-success"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row.type_action
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-warning"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        row.type_action
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.exec_date_format
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.exec_time
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            !row.read_at &&
                                                            _vm.$can(
                                                              "personal_transp_log_approve_entry"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.registerEntry(
                                                                          row.uuid
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Accusé Réception"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row.read_at_format
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            row.read_at &&
                                                            !row.done &&
                                                            _vm.$can(
                                                              "personal_transp_log_approve_exit"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-warning",
                                                                    attrs: {
                                                                      disabled:
                                                                        row.exec_date !=
                                                                        _vm.nows
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.registerExit(
                                                                          row.uuid
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Confirmer l'exécution"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.done_at_format
                                                                    )
                                                                  )
                                                                ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                },
                                [
                                  _c("dataset-show", {
                                    attrs: { "ds-show-entries": 5 }
                                  }),
                                  _c("dataset-pager")
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "b-card",
              { attrs: { title: "Information Générales" } },
              [
                _c("b-card-body", [
                  _c(
                    "table",
                    { staticClass: "table", staticStyle: { width: "100%" } },
                    [
                      _c("tr", [
                        _c("td", [_c("h5", [_vm._v("Structure d'accueil: ")])]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedRow.id
                                ? _vm.selectedRow.visitor_support.structure.name
                                : "N/A"
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _c("h5", [_vm._v("Structure Bénéficiere:")])
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedRow.id
                                ? _vm.selectedRow.visitor_support.company_name
                                : "N/A"
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_c("h5", [_vm._v("Arrivé/Départ:")])]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedRow.id
                                ? _vm.selectedRow.type_action
                                : "N/A"
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_c("h5", [_vm._v("Nombres de Personnes:")])]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedRow.id
                                ? _vm.selectedRow.total_visitors
                                : "N/A"
                            )
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm.selectedRow.entries
          ? _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "b-card",
                  { attrs: { title: "Liste des Personnes" } },
                  [
                    _c("b-card-body", [
                      _c(
                        "table",
                        {
                          staticClass: "table",
                          staticStyle: { width: "100%" }
                        },
                        _vm._l(_vm.selectedRow.entries, function(entry, i) {
                          return _c("tr", { key: i }, [
                            _c("td", [_c("h6", [_vm._v("Nom et Prénom: ")])]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(entry.full_name))
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }